import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    project_names: [],
    project_service: "",
    project_package: "",
    projects: [],
    consultants: [],
    months: [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ],
  },
  mutations: {
    /**
     * Set all projects
     * Gets mounted from the start
     * @type {array}
     * @public This is a public method
     */
    SET_PROJECTS(state, payload) {
      let projects = payload;
      state.projects = projects;
    },
    /**
     * Set all consultants
     * Gets mounted from the start
     * @type {array}
     * @public This is a public method
     */
    SET_CONSULTANTS(state, payload) {
      state.consultants = payload;
    },
    /**
     * Searched project names
     * Gets called when the user selects from list
     * @type {string}
     * @public
     */
    SET_PROJECT_NAMES(state, payload) {
      state.project_names = payload;
    },
    /**
     * Searched project services
     * Gets called when the user selects from list
     * @type {string}
     * @public
     */
    SET_PROJECT_SERVICE(state, payload) {
      state.project_service = payload;
    },
    /**
     * Searched project packages
     * Gets called when the user selects from list
     * @type {string}
     * @public
     */
    SET_PROJECT_PACKAGE(state, payload) {
      state.project_package = payload;
    },

  },
  getters: {
    /**
     * Filter projects based on project_names / service / package
     * Gets called when the user starts typing on the input field
     * @type {array}
     * @public This is a public method
     */
    filteredProjects: (state) => {
      return state.projects.filter((v) => {
        if (
          state.project_names.length > 0 &&
          state.project_service.length == 0 &&
          state.project_package.length == 0
        ) {
          return state.project_names.includes(v["name"]);
        } else if (
          state.project_names.length == 0 &&
          state.project_service.length > 0 &&
          state.project_package.length == 0
        ) {
          return v["service"] == state.project_service;
        } else if (
          state.project_names.length == 0 &&
          state.project_service.length == 0 &&
          state.project_package.length > 0
        ) {
          return v["package"] == state.project_package;
        } else if (
          state.project_names.length > 0 &&
          state.project_service.length > 0 &&
          state.project_package.length == 0
        ) {
          return (
            state.project_names.includes(v["name"]) &&
            v["service"] == state.project_service
          );
        } else if (
          state.project_names.length > 0 &&
          state.project_service.length == 0 &&
          state.project_package.length > 0
        ) {
          return (
            state.project_names.includes(v["name"]) &&
            v["package"] == state.project_package
          );
        } else if (
          state.project_names.length == 0 &&
          state.project_service.length > 0 &&
          state.project_package.length > 0
        ) {
          return (
            v["service"] == state.project_service &&
            v["package"] == state.project_package
          );
        } else if (
          state.project_names.length > 0 &&
          state.project_service.length > 0 &&
          state.project_package.length > 0
        ) {
          return (
            state.project_names.includes(v["name"]) &&
            v["service"] == state.project_service &&
            v["package"] == state.project_package
          );
        } else {
          return (
            state.project_names.includes(v["name"]) ||
            v["service"] == state.project_service ||
            v["package"] == state.project_package
          );
        }
      });
    },
    /**
     * Filter consultants based on only first project_name (SET_PROJECT_NAMES)
     * Don't show if there are more then one projects selected
     * Gets called when the user starts typing on the input field
     * @param {Object} state - consultants
     * @return {Object[]} Filtered consultants
     * @public This is a public method
     */
    filteredConsultants: (state) => {
      return state.consultants.filter((consultant) =>
        // It seems that some consultant objects do not have the project property,
        // which leads to the following issue: https://github.com/wbrp/wbrp_post_tool/issues/49
        // For now, we'll ignore consultants without projects.
        // TODO: Andrea Heiz requested us to prevent the creation of time entries with no associated projects.
        // NOTE: This may not be easily implementable. See https://forum.strapi.io/t/required-relation-field/624/28.
        consultant.project != null? state.project_names.includes(consultant.project["name"]) : false
      );
    },
    /**
     * Finds total number of months entered by consultants for a certain project
     * @type {integer}
     * @public This is a public method
     */
    totalBilledHours: (state, getters) => {
      let sum = 0;
      getters.filteredConsultants.forEach(function(consultant) {
        sum +=
          consultant.january +
          consultant.february +
          consultant.march +
          consultant.april +
          consultant.may +
          consultant.june +
          consultant.july +
          consultant.august +
          consultant.september +
          consultant.october +
          consultant.november +
          consultant.december;
      });
      return sum;
    },
    /**
     * Finds total number of packages entered by consultants for a certain project
     * @type {integer}
     * @public This is a public method
     */
    totalPackageFlatRate: (state, getters) => {
      let sum = 0;
      getters.filteredConsultants.forEach(function(consultant) {
        let hasPackageFlatRate = consultant.name
          .toLowerCase()
          .includes("package");
        if (hasPackageFlatRate) {
          sum +=
            consultant.january +
            consultant.february +
            consultant.march +
            consultant.april +
            consultant.may +
            consultant.june +
            consultant.july +
            consultant.august +
            consultant.september +
            consultant.october +
            consultant.november +
            consultant.december;
        }
      });
      return sum;
    },
    /**
     * Conditional packageFee value for a certain project
     * @type {integer}
     * @public This is a public method
     */
    packageFee: (state, getters) => {
      let sum = 0;
      getters.filteredProjects.forEach(function(project) {
        if (project.package == "no package") {
          sum = 0;
        }
        if (project.package == "basic") {
          sum += 100;
        }
        if (project.package == "medium") {
          sum += 200;
        }
        if (project.package == "advanced") {
          sum += 300;
        }
      });
      return sum;
    },
    /**
     * Finds hourlyFee of a certain project
     * @type {integer}
     * @public This is a public method
     */
    hourlyFee: (state, getters) => {
      let agency_fee_rabattiert = 0;
      let runtime = 0;
      let sold_hours = 0;
      getters.filteredProjects.forEach(function(project) {
        agency_fee_rabattiert = project.agency_fee_rabattiert;
        runtime = project.runtime;
        sold_hours = project.sold_hours;
      });
      return Math.round(
        (agency_fee_rabattiert - getters.packageFee * runtime) / sold_hours
      );
    },
    /**
     * Finds soldHours of a certain project
     * @type {integer}
     * @public This is a public method
     */
    soldHours: (state, getters) => {
      let sold_hours = 0;
      getters.filteredProjects.forEach(function(project) {
        sold_hours = project.sold_hours;
      });
      let totalSoldHours =
        sold_hours - (getters.totalBilledHours - getters.totalPackageFlatRate);
      return totalSoldHours.toFixed(2);
    },
    /**
     * Finds sharePercentage of a certain project
     * @type {integer}
     * @public This is a public method
     */
    sharePercentage: (state, getters) => {
      let agency_fee_rabattiert = 0;
      let mediaspend = 0;
      getters.filteredProjects.forEach(function(project) {
        agency_fee_rabattiert = project.agency_fee_rabattiert;
        mediaspend = project.mediaspend;
      });
      return Math.round(
        (100 / (agency_fee_rabattiert + mediaspend)) * agency_fee_rabattiert
      );
    },
    /**
     * Finds total number of months entered by consultants for a certain project
     * @type {integer}
     * @public This is a public method
     */
    totalEnteredMonths: (state, getters) => {
      let sum = 0;
      let jan = 0;
      let feb = 0;
      let mar = 0;
      let apr = 0;
      let may = 0;
      let jun = 0;
      let jul = 0;
      let aug = 0;
      let sep = 0;
      let oct = 0;
      let nov = 0;
      let dec = 0;
      getters.filteredConsultants.forEach(function(consultant) {
        consultant.january ? (jan = 1) : "",
          consultant.february ? (feb = 1) : "";
        consultant.march ? (mar = 1) : "",
          consultant.april ? (apr = 1) : "",
          consultant.may ? (may = 1) : "",
          consultant.june ? (jun = 1) : "",
          consultant.july ? (jul = 1) : "",
          consultant.august ? (aug = 1) : "",
          consultant.september ? (sep = 1) : "",
          consultant.october ? (oct = 1) : "",
          consultant.november ? (nov = 1) : "",
          consultant.december ? (dec = 1) : "";
        sum =
          jan + feb + mar + apr + may + jun + jul + aug + sep + oct + nov + dec;
      });
      return sum;
    },
    /**
     * Returns current month as integer (0=jan, 1=feb, ...)
     * @type {integer}
     * @public This is a public method
     */
    currentMonth: () => {
      const date = new Date();
      let month = date.getMonth();
      return month;
    },
    /**
     * Package flat rate calculated by < or = current month
     * @type {float}
     * @public This is a public method
     */
    totalPackageFlatRateCalcMonthly: (state, getters) => {
      let sum = 0;
      let currentMonth = getters.currentMonth;
      const months = state.months;
      getters.filteredConsultants.forEach(function(consultant) {
        let hasPackageFlatRate = consultant.name
          .toLowerCase()
          .includes("package");
        if (hasPackageFlatRate) {
          for (let i = 0; i <= currentMonth; i++) {
            sum += consultant[months[i]];
          }
        }
      });
      return sum;
    },
    /**
     * Sold hours calculated by < or = current month
     * @type {float}
     * @public This is a public method
     */
    soldHoursCalcMonthly: (state, getters) => {
      let totalSoldHoursCalcMonthly =
        getters.totalBilledHoursCalcMonthly -
        getters.totalPackageFlatRateCalcMonthly;
      return totalSoldHoursCalcMonthly.toFixed(2);
    },
    /**
     * Billed Hours calculated by < or = current month
     * @type {float}
     * @public This is a public method
     */
    totalBilledHoursCalcMonthly: (state, getters) => {
      let totalBilledHours = 0;
      const months = state.months;
      getters.filteredConsultants.forEach((consultant) => {
        for (let i = 0; i <= getters.currentMonth; i++) {
          totalBilledHours += consultant[months[i]];
        }
      });
      return totalBilledHours;
    },
    /**
     * Agency fee rabattiert calculated by < or = current month
     * @type {float}
     * @public This is a public method
     */
    agencyFeeRabattiertCalcMonthly: (state, getters) => {
      let totalBilledHoursCalcMonthly = getters.totalBilledHoursCalcMonthly;
      let hourlyFee = getters.hourlyFee;
      return totalBilledHoursCalcMonthly * hourlyFee;
    },
    /**
     * Runtime calculated by < or = current month
     * @type {float}
     * @public This is a public method
     */
    runtimeCalcMonthly: (state, getters) => {
      let currentMonth = getters.currentMonth;
      return currentMonth + 1;
    },
  },
  modules: {},
});
