<template>
  <div>
    <div class="container">
      <div class="row">
        <div class=" mb-3 col-md-12">
          <div v-for="project in filteredProjects" :key="project.index">
            <div class="card mb-3 text-center bg-light">
              <div class="card-header">
                <font-awesome-icon icon="chart-pie" size="lg" class="mr-3" />
                <b>Percentage Stats</b>
              </div>
              <div class="card-body">
                <p>{{ project.name }}</p>
                <p
                  :class="[
                    checkPercentage(
                      percentageFeeBilled(agencyFeeRabattiertCalcMonthly)
                    ) >= 75
                      ? 'text-white bg-danger'
                      : '',
                    'card-text',
                  ]"
                >
                  Percentage fee billed:
                  {{
                    percentageFeeBilled(
                      agencyFeeRabattiertCalcMonthly,
                      project.agency_fee_rabattiert
                    )
                  }}
                  %
                </p>
                <p class="card-text">
                  Percentage hours billed:
                  {{
                    percentageHoursBilled(
                      soldHoursCalcMonthly,
                      project.sold_hours
                    )
                  }}
                  %
                </p>
                <p class="card-text">
                  Percentage advanced time:
                  {{
                    percentageAdvancedTime(runtimeCalcMonthly, project.runtime)
                  }}
                  %
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-white {
  display: inline-block;
  padding: 5px;
}
</style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
export default {
  name: "PercentageStats",
  computed: {
    ...mapState(["projects", "consultants", "project_names", "months"]),
    ...mapGetters([
      "filteredProjects",
      "filteredConsultants",
      "totalBilledHours",
      "totalPackageFlatRate",
      "totalEnteredMonths",
      "packageFee",
      "hourlyFee",
      "currentMonth",
      "agencyFeeRabattiertCalcMonthly",
      "soldHoursCalcMonthly",
      "runtimeCalcMonthly",
    ]),
  },
  methods: {
    /**
     * if it is high, add red color to card-text
     * @type {boolean}
     */
    checkPercentage(val) {
      return Math.round(val);
    },
    /**
     * Finds the percentage of the billed fee
     * @type {float}
     */
    percentageFeeBilled(agencyFeeRabattiertCalcMonthly, agency_fee_rabattiert) {
      return this.checkPercentage(
        Math.round(
          (agencyFeeRabattiertCalcMonthly * 100) / agency_fee_rabattiert
        )
      );
    },
    /**
     * Finds the percentage of the billed hours
     * @type {float}
     */
    percentageHoursBilled(soldHoursCalcMonthly, sold_hours) {
      return Math.round((soldHoursCalcMonthly * 100) / sold_hours);
    },
    /**
     * Finds the percentage of the advanced time
     * @type {float}
     */
    percentageAdvancedTime(runtimeCalcMonthly, runtime) {
      return Math.round((runtimeCalcMonthly * 100) / runtime);
    },
  },
};
</script>
