<template>
  <div>
    <!-- Select Project -->
    <div class="container border mt-4">

      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="exclude_projects" checked>
            <label class="form-check-label" for="flexCheckDefault">Exclude old projects</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 mt-4">
          <div class="form-group">
            <label for="">Select by Name:</label>
            <v-select
              v-model="project_names"
              as="label:name:id"
              :from="projects"
              :close-on-select="false"
              :clear-on-select="false"
            />
          </div>
        </div>
        <div class="col-md-4 mt-4">
          <div class="form-group">
            <label for="">Select by Service:</label>
            <select v-model="project_service" class="form-control">
              <option value="">Select one</option>
              <option value="SEA">SEA</option>
              <option value="PRO">PRO</option>
              <option value="CRE">CRE</option>
              <option value="SOM">SOM</option>
              <option value="DLX">DLX</option>
              <option value="CSM">CSM</option>
              <option value="CLT">CLT</option>
              <option value="SEO">SEO</option>
              <option value="SWE">SWE</option>
              <option value="MTU">MTU</option>
              <option value="ECS">ECS</option>
              <option value="SWR">SWR</option>
            </select>
          </div>
        </div>
        <div class="col-md-4 mt-4">
          <div class="form-group">
            <label for="">Select by Package</label>
            <select v-model="project_package" class="form-control">
              <option value="">Select one</option>
              <option value="no package">no package</option>
              <option value="basic">basic</option>
              <option value="medium">medium</option>
              <option value="advanced">advanced</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Show Results -->
    <transition name="route">
      <div
        v-if="project_names.length > 0 || project_service || project_package"
      >
        <ShowResults></ShowResults>
      </div>
    </transition>
  </div>
</template>

<script>
import ShowResults from "@/components/ShowResults.vue";
export default {
  name: "SelectProject",
  components: {
    ShowResults,
  },
  methods: {
    // Check if string contains any of the strings.
    // If yes, return true, else false.
    _starts_with_any(str, substrings) {
      for(const substring of substrings) {
        if (str.toLowerCase().startsWith(substring.toLowerCase())) {
          console.log(`Project "${str}" starts with "${substring}", so it will be ignored.`);
          return true;
        }
      }
      return false;
    }
  },
  computed: {
    projects() {
      const projects = this.$store.state.projects;
      console.log("Number of projects =", projects.length)
      // Exclude projects that contain "old " or "old_"
      if (this.exclude_projects) {
        const nonOldProjects = projects.filter((project) => !this._starts_with_any(project.name, ["old ", "old_"]));
        console.log("Number of projects (after filtering) =", nonOldProjects.length);
        return nonOldProjects;
      } else {
        return projects;
      }
    },
  },
  data() {
    return {
      project_names: [],
      project_service: "",
      project_package: "",
      exclude_projects: true,
    };
  },
  watch: {
    project_names: function(val) {
      this.$store.commit("SET_PROJECT_NAMES", val);
    },
    project_service: function(val) {
      this.$store.commit("SET_PROJECT_SERVICE", val);
    },
    project_package: function(val) {
      this.$store.commit("SET_PROJECT_PACKAGE", val);
    },
  },
};
</script>
