<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- Consultants -->
          <div
            v-for="consultant in filteredConsultants"
            :key="consultant.index"
          >
            <div class="card mb-3 text-center">
              <div class="card-header">
                <font-awesome-icon icon="user-alt" size="lg" class="mr-3" />
                <b>{{ consultant.name }}</b>
              </div>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">January</th>
                      <th scope="col">February</th>
                      <th scope="col">March</th>
                      <th scope="col">April</th>
                      <th scope="col">May</th>
                      <th scope="col">June</th>
                      <th scope="col">July</th>
                      <th scope="col">August</th>
                      <th scope="col">September</th>
                      <th scope="col">October</th>
                      <th scope="col">November</th>
                      <th scope="col">December</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>CHF</th>
                      <th scope="row">
                        {{ formatDecimals(consultant.january * hourlyFee) }}
                      </th>
                      <td>
                        {{ formatDecimals(consultant.february * hourlyFee) }}
                      </td>
                      <td>
                        {{ formatDecimals(consultant.march * hourlyFee) }}
                      </td>
                      <td>
                        {{ formatDecimals(consultant.april * hourlyFee) }}
                      </td>
                      <td>{{ formatDecimals(consultant.may * hourlyFee) }}</td>
                      <td>{{ formatDecimals(consultant.june * hourlyFee) }}</td>
                      <td>{{ formatDecimals(consultant.july * hourlyFee) }}</td>
                      <td>
                        {{ formatDecimals(consultant.august * hourlyFee) }}
                      </td>
                      <td>
                        {{ formatDecimals(consultant.september * hourlyFee) }}
                      </td>
                      <td>
                        {{ formatDecimals(consultant.october * hourlyFee) }}
                      </td>
                      <td>
                        {{ formatDecimals(consultant.november * hourlyFee) }}
                      </td>
                      <td>
                        {{ formatDecimals(consultant.december * hourlyFee) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class="card-text">
                  Total:
                  {{
                    formatDecimals(
                      consultant.january * hourlyFee +
                        consultant.february * hourlyFee +
                        consultant.march * hourlyFee +
                        consultant.april * hourlyFee +
                        consultant.may * hourlyFee +
                        consultant.june * hourlyFee +
                        consultant.july * hourlyFee +
                        consultant.august * hourlyFee +
                        consultant.september * hourlyFee +
                        consultant.october * hourlyFee +
                        consultant.november * hourlyFee +
                        consultant.december * hourlyFee
                    )
                  }}
                  CHF
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table {
  th {
    font-weight: normal;
    font-size: 12px;
  }
  td {
    font-size: 12px;
  }
}
</style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import * as helpers from "@/helpers.js";
export default {
  name: "Consultant",
  computed: {
    ...mapState(["consultants", "project_names"]),
    ...mapGetters([
      "filteredProjects",
      "filteredConsultants",
      "packageFee",
      "hourlyFee",
    ]),
  },
  methods: {
    formatDecimals(val) {
      return helpers.formatDecimals(val);
    },
  },
};
</script>
