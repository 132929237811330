<template>
  <div>
    <div class="container">
      <div class=" mb-3">
        <div class="card text-center bg-light">
          <div class="card-header">
            <font-awesome-icon icon="chart-pie" size="lg" class="mr-3" />
            <b>{{ title }}</b>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <p class="card-text">
                  Budget All-in TOTAL:
                  {{ getTotals("budget_all_in") }}
                  CHF
                </p>
                <p class="card-text">
                  Agency Fee brutto TOTAL:
                  {{ getTotals("agency_fee_brutto") }}
                  CHF
                </p>
                <p class="card-text">
                  Agency Fee rabattiert TOTAL:
                  {{ getTotals("agency_fee_rabattiert") }}
                  CHF
                </p>
                <p class="card-text">
                  Mediaspend TOTAL:
                  {{ getTotals("mediaspend") }}
                  CHF
                </p>
              </div>

              <div class="col-md-4">
                <p class="card-text">
                  Savings TOTAL:
                  {{ getTotals("savings") }}
                  CHF
                </p>
                <p class="card-text">
                  Sold hours TOTAL:
                  {{ getTotals("sold_hours") }}
                  CHF
                </p>
                <p class="card-text">
                  <del
                    >Hourly fee TOTAL:
                    {{ hourlyFeeTotals }}
                    CHF</del
                  >
                </p>
                <p class="card-text">
                  Share TOTAL:
                  {{ shareTotals }}
                  %
                </p>
              </div>

              <div class="col-md-4">
                <div class="card-text">
                  <ul class="list-unstyled">
                    <li>January: {{ getMonthTotals("january") }}</li>
                    <li>February: {{ getMonthTotals("february") }}</li>
                    <li>March: {{ getMonthTotals("march") }}</li>
                    <li>April: {{ getMonthTotals("april") }}</li>
                    <li>May: {{ getMonthTotals("may") }}</li>
                    <li>June: {{ getMonthTotals("june") }}</li>
                    <li>July: {{ getMonthTotals("july") }}</li>
                    <li>August: {{ getMonthTotals("august") }}</li>
                    <li>September: {{ getMonthTotals("september") }}</li>
                    <li>October: {{ getMonthTotals("october") }}</li>
                    <li>November: {{ getMonthTotals("november") }}</li>
                    <li>December: {{ getMonthTotals("december") }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <p class="card-text">
              Package Overview:
              {{ getPackageTotals }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-white {
  display: inline-block;
  padding: 5px;
}
.card-text {
  ul li {
    font-size: 12px;
  }
}
</style>

<script>
import * as helpers from "@/helpers.js";
export default {
  name: "PercentageStats",
  props: ["sort_projects"],
  data() {
    return {
      title: this.sort_projects,
    };
  },
  computed: {
    projects() {
      return this.$store.state.projects;
    },
    project_names() {
      return this.$store.state.project_names;
    },
    filteredProjects() {
      return this.$store.getters.filteredProjects;
    },
    filteredConsultants() {
      return this.$store.getters.filteredConsultants;
    },
    sortedProjects() {
      if (this.sort_projects == "allProjects") {
        return this.$store.state.projects;
      } else {
        return this.$store.getters.filteredProjects;
      }
    },
    consultants() {
      return this.$store.state.consultants;
    },
    hourlyFeeTotals() {
      let result = 0;
      let sum_agency_fee_rabattiert = 0;
      let sum_sold_hours = 0;
      this.sortedProjects.forEach(function(project) {
        sum_agency_fee_rabattiert += project.agency_fee_rabattiert;
        sum_sold_hours += project.sold_hours;
        result = sum_agency_fee_rabattiert / sum_sold_hours;
      });
      return this.formatDecimals(result);
    },
    shareTotals() {
      let result = 0;
      let sum_agency_fee_rabattiert = 0;
      let sum_mediaspend = 0;
      this.sortedProjects.forEach(function(project) {
        sum_agency_fee_rabattiert += project.agency_fee_rabattiert;
        sum_mediaspend += project.mediaspend;
        result =
          (100 / (sum_agency_fee_rabattiert + sum_mediaspend)) *
          sum_agency_fee_rabattiert;
      });
      return this.formatDecimals(result);
    },
    getPackageTotals() {
      let sum = 0;
      this.sortedProjects.forEach(function(project) {
        if (project.package) {
          sum++;
        }
      });
      return sum;
    },
  },
  methods: {
    /**
     * Get specific field totals
     * @type {float}
     */
    getTotals(field) {
      let sum = 0;
      this.sortedProjects.forEach(function(project) {
        sum += project[field];
      });
      return this.formatDecimals(sum);
    },
    /**
     * Get specific month totals
     * @type {float}
     */
    getMonthTotals(month) {
      let consultants =
        this.title == "filteredProjects"
          ? this.filteredConsultants
          : this.consultants;
      let sum = 0;
      consultants.forEach(function(consultant) {
        sum += consultant[month];
      });
      return this.formatDecimals(sum);
    },
    formatDecimals(val) {
      return helpers.formatDecimals(val);
    },
  },
};
</script>
