/**
 * Format decimals xxx'xxx.xx
 * @type {float}
 */
export function formatDecimals(payload) {
  const formatter = new Intl.NumberFormat("de-CH", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(payload);
}
