<template>
  <div id="app">
    <Header/>
    <router-view/>
  </div>
</template>

<style lang="scss">
p {
  font-size: 12px;
}

.wrapper {
  position: relative;
  min-width: 306px;
  height: 35px;
}

.pos-left {
  position: absolute;
  top: 0px;
  left: 0px;
}

.pos-right {
  position: absolute;
  top: 0px;
  right: 0px;
}

// Transition effect
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.route-enter-active,
.route-leave-acitve {
  transition: all 0.7s ease;
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>

<script>
import {library} from "@fortawesome/fontawesome-svg-core";
import {faTasks} from "@fortawesome/free-solid-svg-icons";
import {faMoneyCheckAlt} from "@fortawesome/free-solid-svg-icons";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons";
import {faChartPie} from "@fortawesome/free-solid-svg-icons";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

library.add(faTasks);
library.add(faMoneyCheckAlt);
library.add(faCalendarAlt);
library.add(faUserAlt);
library.add(faChartPie);
library.add(faFilePdf);

import axios from "axios";
import Header from "@/components/Header.vue";


// Strapi v4 returns data in a different way than Stapi v3, so with this need adapter.
// https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html
// https://docs.strapi.io/dev-docs/api/rest
function getData(response) {
  const data = response.data.data;
  const newData = data.map(function (item) {
    return Object.assign({}, {"id": item.id}, item.attributes);
  });
  return newData;
}

function getProject(project) {
  // Some consultants forgot to specify a project.
  if (!project) {
    return null;
  }
  const newProject = Object.assign({}, {"id": project.id}, project.attributes);
  return newProject;
}

// Adapt the project field of the consultants to be like it was in Strapi v3.
function getConsultants(consultants) {
  const newConsultants = consultants.map(function (consultant) {
    return Object.assign({}, consultant, {project: getProject(consultant.project.data)});
  })
  return newConsultants;
}

export default {
  name: "App",
  components: {
    Header,
  },
  mounted() {
    axios
        .get(process.env.VUE_APP_URL + "/api/projects")
        .then((response) => {
          const projects = getData(response);
          this.$store.commit("SET_PROJECTS", projects);
        });
    axios
        // In Strapi 4, relations data are not returned by default.
        .get(process.env.VUE_APP_URL + "/api/consultants?populate=*")
        .then((response) => {
          let consultants = getData(response);
          let newConsultants = getConsultants(consultants);
          this.$store.commit("SET_CONSULTANTS", newConsultants);
        });
  },
};
</script>
