<template>
  <div>

    <!-- Report button -->
    <div class="container mt-3 mb-3">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary" @click="generateReport()">
            <font-awesome-icon icon="file-pdf" size="lg" class="mr-3" />
            Generate Report
          </button>
        </div>
      </div>
    </div>

    <!-- Report document viewport-->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="3400"
      filename="WR_TTC_Bericht"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a3"
      pdf-orientation="landscape"
      pdf-content-width="1700px"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="container">
          <div class="row my-3">
            <div class="col-md-12">
              <img src="@/assets/img/logo.png" class="img-fluid mb-3" />
              <h6>{{ project_names }}</h6>
            </div>
          </div>
        </div>
        <Project></Project>
        <MonthTotals></MonthTotals>
        <PercentageStats></PercentageStats>
      </section>
    </vue-html2pdf>

    <!-- View Results -->
    <OtherStats sort_projects="filteredProjects"></OtherStats>
    <OtherStats sort_projects="allProjects"></OtherStats>
    <Project></Project>
    <MonthTotals></MonthTotals>
    <PercentageStats></PercentageStats>
    <Consultant></Consultant>

</div>
</template>

<script>
import OtherStats from "@/components/OtherStats.vue";
import Project from "@/components/Project.vue";
import MonthTotals from "@/components/MonthTotals.vue";
import PercentageStats from "@/components/PercentageStats.vue";
import Consultant from "@/components/Consultant.vue";
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    VueHtml2pdf,
    OtherStats,
    Project,
    MonthTotals,
    PercentageStats,
    Consultant,
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  computed: {
    project_names() {
      return this.$store.state.project_names;
    },
  },
  name: "ShowResults",
};
</script>
