<template>
  <div>
    <div class="container">
      <div class=" mb-3">
        <div class="card text-center bg-light">
          <div class="card-header">
            <font-awesome-icon icon="calendar-alt" size="lg" class="mr-3" />
            <b>Months Total</b>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <p class="card-text">
                  January:
                  {{ monthTotals("january") }}
                </p>
                <p class="card-text">February: {{ monthTotals("february") }}</p>
                <p class="card-text">March: {{ monthTotals("march") }}</p>
              </div>
              <div class="col-md-3">
                <p class="card-text">
                  April:
                  {{ monthTotals("april") }}
                </p>
                <p class="card-text">May: {{ monthTotals("may") }}</p>
                <p class="card-text">June: {{ monthTotals("june") }}</p>
              </div>
              <div class="col-md-3">
                <p class="card-text">
                  July:
                  {{ monthTotals("july") }}
                </p>
                <p class="card-text">August: {{ monthTotals("august") }}</p>
                <p class="card-text">
                  September: {{ monthTotals("september") }}
                </p>
              </div>
              <div class="col-md-3">
                <p class="card-text">
                  October:
                  {{ monthTotals("october") }}
                </p>
                <p class="card-text">November: {{ monthTotals("november") }}</p>
                <p class="card-text">December: {{ monthTotals("december") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
p {
  font-size: 14px;
}
</style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import * as helpers from "@/helpers.js";
export default {
  name: "MonthTotals",
  computed: {
    ...mapState(["projects", "consultants", "project_names"]),
    ...mapGetters([
      "filteredProjects",
      "filteredConsultants",
      "packageFee",
      "hourlyFee",
    ]),
  },
  methods: {
    /**
     * Calculate certain Month's total
     * @type {float}
     */
    monthTotals(month) {
      let sum = 0;
      this.filteredConsultants.forEach(function(consultant) {
        sum += consultant[month];
      });
      return this.formatDecimals(sum * this.hourlyFee);
    },
    formatDecimals(val) {
      return helpers.formatDecimals(val);
    },
  },
};
</script>
