var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"mb-3 col-md-12"},_vm._l((_vm.filteredProjects),function(project){return _c('div',{key:project.index},[_c('div',{staticClass:"card mb-3 text-center bg-light"},[_c('div',{staticClass:"card-header"},[_c('font-awesome-icon',{staticClass:"mr-3",attrs:{"icon":"chart-pie","size":"lg"}}),_c('b',[_vm._v("Percentage Stats")])],1),_c('div',{staticClass:"card-body"},[_c('p',[_vm._v(_vm._s(project.name))]),_c('p',{class:[
                  _vm.checkPercentage(
                    _vm.percentageFeeBilled(_vm.agencyFeeRabattiertCalcMonthly)
                  ) >= 75
                    ? 'text-white bg-danger'
                    : '',
                  'card-text',
                ]},[_vm._v(" Percentage fee billed: "+_vm._s(_vm.percentageFeeBilled( _vm.agencyFeeRabattiertCalcMonthly, project.agency_fee_rabattiert ))+" % ")]),_c('p',{staticClass:"card-text"},[_vm._v(" Percentage hours billed: "+_vm._s(_vm.percentageHoursBilled( _vm.soldHoursCalcMonthly, project.sold_hours ))+" % ")]),_c('p',{staticClass:"card-text"},[_vm._v(" Percentage advanced time: "+_vm._s(_vm.percentageAdvancedTime(_vm.runtimeCalcMonthly, project.runtime))+" % ")])])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }