<template>
  <div>
    <div v-for="project in filteredProjects" :key="project.index">
      <div class="mb-3 col-sm">
        <div class="card">
          <div class="card-header">
            <font-awesome-icon icon="tag" size="lg" class="mr-3" />
            <b>Differenz</b>
          </div>
          <div class="card-body">
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Project Name:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">{{ project.name }}</p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Project ID:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">{{ project.id }}</p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Budget All In:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">
                  {{ formatDecimals(project.budget_all_in) }}
                  CHF
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Agency Fee Brutto:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">
                  {{ formatDecimals(project.agency_fee_brutto) }}
                  CHF
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Agency Fee Rabattiert:</p>
              </div>
              <div class="pos-right">
                <p class="bg-warning card-text">
                  {{ agencyFeeRabattiert(project.agency_fee_rabattiert) }}
                  CHF
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Media Spend:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">
                  {{ formatDecimals(project.mediaspend) }}
                  CHF
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Savings:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">
                  {{ formatDecimals(project.savings) }}
                  CHF
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Sold hours:</p>
              </div>
              <div class="pos-right">
                <p class="bg-warning card-text">
                  {{ soldHours(project.sold_hours) }}
                  hrs.
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Hourly Fee:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">
                  {{ hourlyFee }}
                  CHF
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Runtime:</p>
              </div>
              <div class="pos-right">
                <p class="bg-warning card-text">
                  {{ runtime(project.runtime) }} mos.
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Service:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">
                  {{ project.service }}
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Languages:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">
                  {{ project.languages }}
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Package:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">
                  {{ project.package }}
                </p>
              </div>
            </div>
            <div class="wrapper">
              <div class="pos-left">
                <p class="card-text">Share:</p>
              </div>
              <div class="pos-right">
                <p class="card-text">
                  {{ sharePercentage }}
                  %
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import * as helpers from "@/helpers.js";
export default {
  name: "ProjectDifference",
  computed: {
    ...mapState(["projects", "consultants", "project_names", "months"]),
    ...mapGetters([
      "filteredProjects",
      "filteredConsultants",
      "totalBilledHours",
      "totalEnteredMonths",
      "packageFee",
      "hourlyFee",
      "sharePercentage",
      "currentMonth",
      "agencyFeeRabattiertCalcMonthly",
      "soldHoursCalcMonthly",
      "runtimeCalcMonthly",
    ]),
  },
  methods: {
    formatDecimals(val) {
      return helpers.formatDecimals(val);
    },
    agencyFeeRabattiert(agency_fee_rabattiert) {
      return this.formatDecimals(
        agency_fee_rabattiert - this.agencyFeeRabattiertCalcMonthly
      );
    },
    soldHours(sold_hours) {
      return this.formatDecimals(sold_hours - this.soldHoursCalcMonthly);
    },
    runtime(runtime) {
      return runtime - this.runtimeCalcMonthly;
    },
  },
};
</script>
