<template>
  <div>
    <div class="container">
      <div class="row">
        <ProjectFacts />
        <ProjectExpenses />
        <ProjectDifference />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-text {
  font-size: 12px;
}
</style>

<script>
import ProjectFacts from "@/components/ProjectFacts.vue";
import ProjectExpenses from "@/components/ProjectExpenses.vue";
import ProjectDifference from "@/components/ProjectDifference.vue";
export default {
  name: "Project",
  components: {
    ProjectFacts,
    ProjectExpenses,
    ProjectDifference,
  },
};
</script>
